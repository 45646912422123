
.cart_right_firstDiv{
    width: 100%;
    padding-top: 8%;
    padding-bottom: 8%;
    padding-left: 10%;
    padding-right: 10%;
    border-radius: 12px;
    background-color: #fff;
    margin-top: 4.5%;
}


.MdOutlineSecurity-icon{
    color: red;
    cursor: pointer;
}
.settings_header_right_title{
    color: #000;
    font-style: normal;
    font-weight: 600;
    font-size: 1.2em;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 10px !important;
}
.cart_hr{
    width: 13%;
    border-top: 0.5px solid #232323;
    margin-bottom: 5%;

}
.cart_image_empty{
    height: 65px;
    width: 65px;
    border-radius: 35px;
}


p.cart_walletAddress{
    font-size: 13.5px;
    color: #8A8A8A;
    margin-left: 8%;
    margin-top: 8px;
    font-family: 'Rubik', sans-serif;
}
.FaRegCopy-icon{
    margin-top: -4px;
    margin-left: 8px;
    color: #62626A;
    cursor: pointer;
}
p.totalDue{
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Rubik', sans-serif;
}
p.totalAmmount{
    text-align: right;
    font-weight: 600;
}
button.checkOut{
    background-color: #0D67FE;
    height: 50px;
    width: 100%;
    border-radius: 10px;
    color: #fff;
    border: 0px solid #0D67FE;
    margin-top: 3%;
    font-size: 17px;
    font-family: 'Rubik', sans-serif;
}
button.checkOut_inactive{
    background-color: #8A8A8A;
    height: 50px;
    width: 100%;
    border-radius: 10px;
    color: #fff;
    border: 0px solid #0D67FE;
    margin-top: 3%;
    cursor: not-allowed !important;
    font-size: 17px;
    font-family: 'Rubik', sans-serif;
}
button.checkOut:hover{
    background-color: #4E90FF;
}

p.enter_code_cart{
    color: #858585;
    margin-top: 4px;
    margin-bottom: 5px;
}
input.referenceRedemInput{
    width: 100%;
    margin-top: 10px;
    border: 0px solid #fff;
    background-color: transparent;
}
input.referenceRedemInput:focus {
    outline:none;
}
input.referenceRedemInput2{
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0px solid #fff;
    background-color: transparent;
}
input.referenceRedemInput2:focus {
    outline:none;
}

.FaCheck-icon{
    color: #00D6AF;
    margin-right: 5px;
}
.FaCheck-icon2{
    color: #00D6AF;
    margin-right: 5px;
    margin-top: 5px;
}
.cart_left_float_new{
    float: left;
}

.cart_social_button{
    box-sizing: border-box;
    width: 130px;
    margin-top: 0px;
    border: 0px solid #C6C6C6;
    margin-left: 8%;
    cursor: pointer;
}
.cart_social_button_connected{
    box-sizing: border-box;
    width: 140px;
    height: 27px;
    border: 0px solid #C6C6C6;
    border-radius: 5px;
    margin-left: 8%;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #73c255;
    cursor: pointer;
}
.cart_social_button_icon{
    height: 14px;
    object-fit: contain;
}
p.enterEmail_cart{
    color: #242424;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: -7px;
}
p.ref_usersNames{
    text-align: center !important;
    font-weight: 600;
    font-size: 13px;
    margin-top: 5px;
    color: #242424;
    margin-left: -17px;
}
.or_txt{
    color: #858585;
    font-weight: 600;
    font-size: 13px;
    margin-top: 4.5px;
}
span.cart_social_button_text{
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #136EB7;
    text-align: left;
}
span.cart_social_button_text_white{
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #fff;
    text-align: left;
}
@media only screen and (max-width: 767px) {
    p.settings_header_title{
        margin-left: 9px;
    }
    .cart_left_firstDiv{
        width: 100%;
        padding: 5%;
        border-radius: 12px;
        background-color: #fff;
        margin-top: 3%;
        padding-top: 7%;
        padding-bottom: 3%;
    }
    p.card_domainName{
        font-family: 'Rubik', sans-serif;
        font-size: 16.5px;
    }
    p.card_domainPrice{
        font-family: 'Rubik', sans-serif;
        font-size: 16.5px;
        text-align: right;
        font-weight: 600;
    }
    p.red_delete{
        color: red;
        font-size: 13.5px;
        margin-top: 0.5px;
        font-weight: 600;
        text-align: right;
        cursor: pointer;
    }
    p.cart_fullName{
        font-weight: 600;
        font-family: 'Rubik', sans-serif;
        margin-bottom: 0px;
        margin-left: 5%;
        margin-top: 5px;
    }
    .greenRefCODE_box{
        width: 100%;
        padding: 2%;
        padding-left: 4%;
        padding-left: 4%;
        border-radius: 10px;
        border: 1px solid #00D6AF;
        margin-top: 3%;
        margin-bottom: 7%;
    }
    .greenRefCODE_box_new{
        width: 100%;
        padding: 2%;
        padding-left: 4%;
        padding-left: 4%;
        border-radius: 10px;
        border: 1px solid #8A8A8A;
        margin-top: 7%;
        margin-bottom: 10%;
    }
    .greenCodeReddemmed2_black{
        color: #000;
        font-weight: 600;
        margin-bottom: 0px !important;
        font-size: 13.5px;
        margin-top: 7px;
    }
    .cart_image_empty_pointer{
        height: 65px;
        width: 65px;
        border-radius: 35px;
        margin-top: 10px;
        cursor: pointer;
    }
}
@media only screen and (min-width: 768px) {
    .cart_left_firstDiv{
        width: 95%;
        margin-right: 5%;
        padding: 5%;
        padding-right: 4%;
        border-radius: 12px;
        background-color: #fff;
        margin-top: 3%;
        padding-top: 6%;
        padding-bottom: 4%;
    }
    p.card_domainName{
        font-family: 'Rubik', sans-serif;
        font-size: 18px;
    }
    p.card_domainPrice{
        font-family: 'Rubik', sans-serif;
        font-size: 18px;
        text-align: right;
        font-weight: 600;
    }
    p.red_delete{
        color: red;
        font-size: 13px;
        margin-top: 3.5px;
        font-weight: 600;
        text-align: right;
        cursor: pointer;
    }
    p.cart_fullName{
        font-weight: 600;
        font-family: 'Rubik', sans-serif;
        margin-bottom: 0px;
        margin-left: 8%;
        margin-top: 5px;
    }
    .greenRefCODE_box{
        width: 100%;
        padding: 2%;
        padding-left: 7%;
        border-radius: 10px;
        border: 1px solid #00D6AF;
        margin-top: 3%;
        margin-bottom: 7%;
    }
    .greenRefCODE_box_new{
        width: 100%;
        padding: 2%;
        padding-left: 7%;
        padding-right: 7%;
        border-radius: 10px;
        border: 1px solid #8A8A8A;
        margin-top: 3%;
        margin-bottom: 7%;
    }
    .greenCodeReddemmed2_black{
        color: #000;
        font-weight: 600;
        margin-bottom: 0px !important;
        font-size: 13.5px;
        text-align: right;
        margin-top: 7px;
    }
    .cart_image_empty_pointer{
        height: 65px;
        width: 65px;
        border-radius: 35px;
        margin-top: 15px;
        cursor: pointer;
    } 
}
.reference_SEARCH_button_blue{
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    border: 1.6px solid #0D67FE;
    background-color: #0D67FE;
    cursor: pointer;
    padding-top: 8.8px;
    margin-top: 20px;
    border-radius: 10px !important;
}
input.referenceInput{
    border-radius: 8px;
    padding-left: 10px;
    width: 100%;
    margin-right: 2%;
    border: 0px solid #C6C6C6;
    color: #000;
    height: 50px;
    padding-left: 3%;
    margin-top: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
}
input.index_refView_secndInput{
    width: 100%;
    font-size: 15px;
    background: transparent;
    border: 0px solid #C6C6C6;
}

.greenCodeReddemmed{
    color: #00D6AF;
    font-weight: 600;
    margin-bottom: 0px !important;
    font-size: 13.5px;
    margin-top: 3px;
    text-align: right;
    cursor: pointer;
}
.greenCodeReddemmed2{
    color: #00D6AF;
    font-weight: 600;
    margin-bottom: 0px !important;
    font-size: 13.5px;
    text-align: right;
    cursor: pointer;
}

.blackCodeReddemmed{
    color: #606060;
    font-weight: 600;
    margin-bottom: 0px !important;
    font-size: 13.5px;
    text-align: right;
    margin-top: 3px;
    cursor: pointer;
}
.index_refView_secndView{
    border-radius: 8px !important;
    width: 100%;
    border: 0px solid #C6C6C6;
    color: #000;
    height: 54px;
    padding-top: 13px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
}
.index_refView_secndView_green{
    border-radius: 8px !important;
    width: 100%;
    border: 1.4px solid #00D6AF;
    color: #000;
    height: 54px;
    padding-top: 12px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
}
.index_refView_secndView_green_withoutShadow{
    border-radius: 8px !important;
    width: 100%;
    border: 1.4px solid #00D6AF;
    color: #000;
    height: 54px;
    padding-top: 12px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    box-sizing: border-box;
}
.index_refView_secndView_withoutShadow{
    border-radius: 8px !important;
    width: 100%;
    border: 1.6px solid #00000014;
    color: #000;
    height: 54px;
    padding-top: 13px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    box-sizing: border-box;
}
.mopdal_margins{
    padding:2.5%;
    padding-top: 2%;
}