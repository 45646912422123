@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
.bell-icon{
    color: #fff;
    cursor: pointer;
    float: right;
    margin-right: 20px;
}
.loader{
    margin-top: 5%;
}

input.index_refView_secndInput:focus{
    border: 0px solid #136EB7;
    outline:none;
}

p.skip_txt{
    text-align: center;
    cursor: pointer;
    color: #858585;
    margin-top: 5%;
    margin-bottom: 0px;
}
input.referenceInput:focus {
    border: 0px solid #136EB7;
    outline:none;
}
p.refernce_header_text{
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #252525;
    margin-bottom: 3px;
}
p.refernce_header_text_small{
    font-family: 'Rubik', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #858585;
    margin-bottom: 0px;
}
.reference_skip_button{
    box-sizing: border-box;
    width: 110px;
    height: 45px;
    border: 1.6px solid #e8e8e8;
    background-color: #e4e4e4;
    margin-bottom: 1%;
    cursor: pointer;
    padding-top: 13px;
    padding-left: 7%;
    padding-right: 7%;
    margin-top: 5%;
    margin-left: 12%;
    border-radius: 10px !important;
    float: left;
}
.reference_skip_button_blue{
    box-sizing: border-box;
    width: 110px;
    height: 45px;
    border: 1.6px solid #0D67FE;
    background-color: #0D67FE;
    margin-bottom: 1%;
    cursor: pointer;
    padding-left: 7%;
    padding-right: 7%;
    margin-top: 5%;
    border-radius: 10px !important;
    float: left;
}

p.reference_skip_button_txt_blue{
    font-size: 15px;
    text-align: center;
    margin-top: 3.7px;
    letter-spacing: 0.01em;
    font-family: 'Rubik', sans-serif;
}
.referenceModalMargins{
    margin-left: 19%;
}
p.reference_skip_button_txt{
    font-size: 15px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #fff;
    margin-top: 3.7px;
    font-family: 'Rubik', sans-serif;
}
.dashboard_body_2{
    min-height: 100vh;
    width: 100vw;
    background: #F5F5F5;
    padding-top: 7%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 7%;
}
.M2{
    height: 35px;
    object-fit: contain;
}
.dashboard_body_2_margins{
    margin-top: 5%;
}
.Hundred{
    height: 35px;
    object-fit: contain;
    margin-top: 5%;
}
p.M2_txt{
    color: #8F8F8F;
    font-weight: 500;
    margin-top: 10px;
    font-size: 14.5px;
    font-family: 'Rubik', sans-serif;
}
p.Official_Partners{
    color: #000;
    font-weight: 600;
}
.unstoppable_blue{
    height: 45px;
    object-fit: contain;
    margin-top: 3%;
}
.persona_home{
    height: 55px;
    margin-top: 3%;
    object-fit: contain;
    mix-blend-mode: multiply;
}
@media only screen and (min-width: 768px) {
    .home_banner_img{
        width: 95%;
        object-fit: contain;
        margin-left: 10%;
        margin-top: -10%;
    }
    .dashboard_body{
        background-color: #f4f8ff;
        width: 100vw;
        height: 100vh;
        padding-left: 15%;
        padding-right: 15%;
        padding-top: 7%;
        padding-bottom: 7%;
    }
    .dashboard_kyc_box{
        background-color: #fff;
        height: 120px;
        width: 100%;
        height: 130px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 12px;
        align-items: center;
        align-self: center;
        display: flex;
        padding-left: 4%;
        padding-top: 1.4%;
        padding-right: 4%;
        margin-bottom: 7%;
    }
    .dashboard_kyc_box_1{
        background-color: #fff;
        height: 120px;
        width: 90%;
        height: 104px;
        margin-right: 10%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 12px;
        padding: 5%;
        cursor: pointer;
        margin-bottom: 7%;
    }
    .dashboard_kyc_box_2{
        background-color: #fff;
        height: 120px;
        width: 90%;
        height: 104px;
        margin-left: 10%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 12px;
        padding: 5%;
        cursor: pointer;
        margin-bottom: 7%;
    }
    input.dashboard_DomainInput{
        border-radius: 8px;
        padding-left: 10px;
        width: 100%;
        border: 0px solid #C6C6C6;
        height: 66px;
        padding-left: 4%;
        background: #fff;
        font-size: 18px;
        color: #15213C;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);
    }
    button.searchBtn{
        background-color: #0D67FE;
        border: none;
        color: white;
        height: 69px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        width: 100%;
        margin-top: -3px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    button.dashboard_domains_tab_active{
        background-color: #0D67FE;
        border: none;
        color: white;
        height: 55px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 1%;
        margin-left: 1.2%;
        margin-bottom: 4%;
        font-family: 'Rubik', sans-serif;
    }
    button.dashboard_domains_tab_inactive{
        background-color: #EAEAEA;
        border: none;
        color: #242424;
        height: 55px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 1%;
        margin-left: 1.2%;
        margin-bottom: 4%;
        font-family: 'Rubik', sans-serif;
    }
    button.dashboard_domains_tab_active_first{
        background-color: #0D67FE;
        border: none;
        color: white;
        height: 55px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 1%;
        margin-bottom: 4%;
        font-family: 'Rubik', sans-serif;
    }
    button.dashboard_domains_tab_inactive_first{
        background-color: #EAEAEA;
        border: none;
        color: #242424;
        height: 55px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 1%;
        margin-bottom: 4%;
        font-family: 'Rubik', sans-serif;
    }
    button.buyBtn{
        background: #F5F5F5;
        border: 1px solid #D9D9D9;
        border-radius: 5px;
        color: #242424;
        height: 37px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        width: 80%;
        margin-left: 20%;
        margin-top: -4.4px;
    }
    .dashboard_body_marginsLR{
        margin-left: 12%;
        margin-right: 12%;
    }
    p.dashboard_body_div1_txt{
        font-size: 2.6em;
        color: #0D67FE;
        text-align: center;
        font-family: 'Poppins', sans-serif;
    }
    p.dashboard_body_div1_txt2{
        font-size: 2.6em;
        color: #0D67FE;
        font-family: 'Poppins', sans-serif;
    }
    p.dashboard_body_div1_subtxt{
        font-size: 18px;
        margin-top: 15px;
        color: #949494;
        text-align: center;
    }
    p.dashboard_body_div1_subtxt2{
        font-size: 18px;
        margin-top: 15px;
        color: #949494;
    }
    input.dashboard_DomainInput::placeholder{
        color: #B6B6B6;
        font-size: 1em !important;
    }
    .FiSearch-icon{
        margin-top: -3px;
    }
    span.searchBtn_txt{
        margin-left: 5px;
    }
    .domTags_margin{
        margin-top: 2%;
    }
    p.domTags_txt{
        color: #919191;
        font-weight: 600;
    }
    .domTags_first{
        height: 23px;
        width: auto;
        float: left;
        padding-right: 15px;
        border-right: 0.5px solid #ADADAD;
    }
    .domTags_middle{
        height: 23px;
        width: auto;
        float: left;
        padding-left: 15px;
        padding-right: 15px;
        border-right: 0.5px solid #ADADAD;
    }
    .domTags_right{
        height: 23px;
        width: auto;
        float: left;
        padding-left: 15px;
    }
    .dashboard_searchMain{
        background-color: #f5f5f5;
        width: 100vw;
        min-height: 100vh;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 1%;
    }
    .dashbord_div1_margins{
        margin-top: 14% !important;
    }
    p.searchResults_txt{
        font-weight: 600;
        font-size: 17px;
        color: #575757;
        margin-top: 1%;
    }
    .domains_boxes_odd{
        width: 95%;
        margin-right: 5%;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 20px;
        align-items: center;
        align-self: center;
        background-color: #fff;
        color: #000;
        height: 70px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    .domains_boxes_even{
        width: 95%;
        margin-right: 5%;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 20px;
        align-items: center;
        align-self: center;
        background-color: #f1f1f1;
        color: #000;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        height: 70px;
    }
    .domains_boxes_odd_forRight{
        width: 95%;
        margin-left: 5%;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 20px;
        align-items: center;
        align-self: center;
        background-color: #fff;
        color: #000;
        height: 70px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    .domains_boxes_even_forRight{
        width: 95%;
        margin-left: 5%;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 20px;
        align-items: center;
        align-self: center;
        background-color: #f1f1f1;
        color: #000;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        height: 70px;
    }
}
button.buyBtn:hover{
    background-color: #4E90FF;
    color: #fff;
}
span.domains_boxes_odd_txt{
    font-family: 'Rubik', sans-serif;
    font-size: 16.5px;
}
span.domains_boxes_odd_price{
    font-size: 15.5px;
    font-weight: bolder;
    color: #00D6AF;
    text-align: center !important;
}
@media only screen and (max-width: 767px) {
    .dashboard_body_LR_background{
        display: none;
    }
    .dashboard_body_LR_background_gray{
        display: none;
    }
    .dashbord_div1_margins{
        margin-top: 10% !important;
    }
    .home_banner_img{
        width: 95%;
        margin-top: 10px;
        object-fit: contain;
    }
    input.dashboard_DomainInput{
        border-radius: 7px;
        width: 100%;
        border: 0px solid #C6C6C6;
        height: 50px;
        padding-left: 5%;
        background: #fff;
        font-size: 18px;
        color: #15213C;
        box-shadow: 0px 0px 20px rgba(141, 141, 141, 0.13);
    }
    button.searchBtn{
        background-color: #0D67FE;
        border: none;
        color: white;
        height: 50px;
        padding: 3px 2px;
        text-align: center;
        font-size: 16px;
        cursor: pointer;
        border-radius: 7px;
        width: 100% !important;

    }
    .dashboard_body{
        background-color: #ecf3ff;
        width: 100vw;
        min-height: 100vh;
        padding-left: 6%;
        padding-right: 6%;
        padding-top: 15%;
        padding-bottom: 7%;
    }
    .dashboard_kyc_box{
        background-color: #fff;
        height: 120px;
        width: 100%;
        height: 130px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 12px;
        margin-bottom: 15%;
        align-items: center;
        align-self: center;
        display: flex;
        padding-left: 4%;
        padding-top: 6%;
        padding-right: 4%;
    }
    .dashboard_kyc_box_1{
        background-color: #fff;
        height: 120px;
        width: 95%;
        height: 104px;
        margin-right: 5%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 12px;
        padding: 10%;
        margin-bottom: 15%;
    }
    .dashboard_kyc_box_2{
        background-color: #fff;
        height: 120px;
        width: 95%;
        height: 104px;
        margin-left: 5%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 12px;
        padding: 10%;
        margin-bottom: 15%;
    }
    .dashboard_body_marginsLR{
        margin-left: 0% !important;
        margin-right: 0% !important;
    }
    p.dashboard_body_div1_txt{
        font-size: 1.6em;
        color: #0D67FE;
        text-align: center;
        font-family: 'Poppins', sans-serif;
    }
    p.dashboard_body_div1_txt2{
        font-size: 1.6em;
        color: #0D67FE;
        font-family: 'Poppins', sans-serif;
    }
    p.dashboard_body_div1_subtxt{
        font-size: 0.9em;
        margin-top: 15px;
        color: #949494;
        text-align: center;
    }
    p.dashboard_body_div1_subtxt2{
        font-size: 0.9em;
        margin-top: 15px;
        color: #949494;
    }
    input.dashboard_DomainInput::placeholder{
        color: #B6B6B6;
        font-size: 0.8em !important;
    }
    .FiSearch-icon{
       display: none;
    }
    span.searchBtn_txt{
        margin-left: 2px;
        font-size: 14px !important;
    }
    .domTags_margin{
        margin-top: 5%;
    }
    p.domTags_txt{
        color: #919191;
        font-weight: 600;
        font-size: 14px;
    }
    .domTags_first{
        height: 20px;
        width: auto;
        float: left;
        padding-right: 15px;
        margin-bottom: 10px;
        border-right: 0.2px  solid #d3d3d3;
    }
    .domTags_middle{
        height: 20px;
        width: auto;
        float: left;
        padding-left: 15px;
        margin-bottom: 10px;
        padding-right: 15px;
        border-right: 0.2px solid #d3d3d3;
    }
    .domTags_right{
        height: 20px;
        width: auto;
        margin-bottom: 10px;
        float: left;
        padding-left: 15px;
    }
    .dashboard_searchMain{
        background-color: #f5f5f5;
        width: 100vw;
        min-height: 100vh;
        padding-left: 6%;
        padding-right: 6%;
        padding-top: 2%;
    }
    p.searchResults_txt{
        font-weight: 600;
        font-size: 1em;
        color: #575757;
        margin-top: 1%;
    }
    button.dashboard_domains_tab_active{
        background-color: #0D67FE;
        border: none;
        color: white;
        height: 45px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 1%;
        margin-left: 2%;
        margin-bottom: 4%;
        font-family: 'Rubik', sans-serif;
    }
    button.dashboard_domains_tab_inactive{
        background-color: #EAEAEA;
        border: none;
        color: #242424;
        height: 45px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 1%;
        margin-left: 2%;
        margin-bottom: 4%;
        font-family: 'Rubik', sans-serif;
    }
    button.dashboard_domains_tab_active_first{
        background-color: #0D67FE;
        border: none;
        color: white;
        height: 45px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 1%;
        margin-bottom: 4%;
        font-family: 'Rubik', sans-serif;
    }
    button.dashboard_domains_tab_inactive_first{
        background-color: #EAEAEA;
        border: none;
        color: #242424;
        height: 45px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 8px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 1%;
        margin-bottom: 4%;
        font-family: 'Rubik', sans-serif;
    }
    button.buyBtn{
        background: #F5F5F5;
        border: 1px solid #D9D9D9;
        border-radius: 5px;
        color: #242424;
        height: 37px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        width: 80%;
        margin-left: 20%;
        margin-top: -4.4px;
    }
    button.otherBtn{
        display: none;
    }
    .domains_boxes_odd{
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 20px;
        align-items: center;
        align-self: center;
        background-color: #fff;
        color: #000;
        height: 70px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    .domains_boxes_even{
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 20px;
        align-items: center;
        align-self: center;
        background-color: #f1f1f1;
        color: #000;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        height: 70px;
    }
    .domains_boxes_odd_forRight{
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 20px;
        align-items: center;
        align-self: center;
        background-color: #fff;
        color: #000;
        height: 70px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    .domains_boxes_even_forRight{
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 20px;
        align-items: center;
        align-self: center;
        background-color: #f1f1f1;
        color: #000;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        height: 70px;
    }
}
input.dashboard_DomainInput:focus {
    border: 0px solid #113258;
    outline:none;
}

button.searchBtn:hover{
    background-color: #4E90FF;
}
.myClassname{
    height: 100vh !important;
}




.dashboard_body_LR_background{
    background-repeat: no-repeat, repeat;
    background-position: left top;
    background-image: url("../assets/world_map.png");
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    height: 395px;
    width: 64%;
    margin-left: 54%;
    margin-top: -13vw;
}
.dashboard_body_LR_background_gray{
    background-repeat: no-repeat, repeat;
    background-position: left top;
    background-image: url("../assets/world_map_gray.png");
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    height: 50vh;
    width: 50%;
    margin-left: 45vw;
    margin-bottom: -60vh;
}
.responsive_worldMap{
    width: 100%;
    
}