@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');


.BsChevronDown-footer{
  margin-left: 5px;
  color: #000;
  margin-top: -1px;
}
span.footer_english{
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}
span.footer_englishMargins{
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin-left: 6%;
  cursor: pointer;
}
.twitter_black{
  height: 33px;
  object-fit: contain;
  padding: 6.5px;
  border-radius: 100px;
  border: 1px solid #000;
  margin-right: 15px;
  margin-top: -10px;
  cursor: pointer;
}
.linkedIn_black{
  height: 33px;
  width: 33px;
  object-fit: contain;
  padding: 6.5px;
  border-radius: 100px;
  border: 1px solid #000;
  margin-top: -10px;
  cursor: pointer;
}


.blur_footer_txt{
  color: #fff;
  font-size: 14.5px;
  font-family: 'Rubik', sans-serif;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .icons_footer_float{
    float: right;
  }
  .footer_main{
    background-color: #f5f5f5;
    margin: 0px !important;
    margin-top: -20px !important;
    padding-left: 12%;
    padding-right: 12%;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  hr.footer_hr{
    margin-bottom: 3%;
  }
  .blur_footer{
    background-color: #0D67FE;
    height: 45px;
    padding-top: 13px;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .icons_footer_float{
    float: center;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .footer_main{
    background-color: #f5f5f5;
    margin: 0px !important;
    margin-top: -20px !important;
    padding-left: 12%;
    padding-right: 12%;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  hr.footer_hr{
    margin-top: 15%;
  }
  .blur_footer{
    background-color: #0D67FE;
    padding-top: 13px;
    padding-bottom: 2px;
    width: 100%;
    margin-top: 5px;
  }
}