@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
.forgotPassword_main{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 58vh;
    border-radius: 13px;
    width: 24vw;
    padding: 10%;
    padding-right: 10.5%;
}
p.forgotPassword_Title{
    font-family: 'Rubik', sans-serif;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 15%;
}
p.forgotPassword_TitleEmail{
    font-family: 'Rubik', sans-serif;
    font-size: 17px;
    margin-bottom: 1%;
    text-align: left !important;
}
input.forgotPassword_emailInput{
    border-radius: 8px;
    width: 100%;
    border: 1.6px solid #C6C6C6;
    height: 49px;
    padding-left: 7%;
    margin-top: 10px;
}
.forgotPassword_login_button{
    box-sizing: border-box;
    width: 100%;
    height: 51px;
    background-color: #136EB7;
    border-radius: 8px;
    margin-bottom: 5%;
    cursor: pointer;
    align-self: center !important;
    align-items: center !important;
    padding-top: 10.7px;
    padding-left: 7%;
    padding-right: 7%;
    margin-top: 25px;
    margin-bottom: 25px;
}
.forgotPassword_login_button:hover{
    background-color: #4E90FF;
}
p.forgotPassword_Back{
    font-size: 17px;
    color: #136EB7;
    font-family: 'Rubik', sans-serif;
    cursor: pointer;
    font-weight: 600;
}
input.forgotPassword_emailInput:focus {
    border: 2px solid #136EB7;
    outline:none;
}