@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
.menu-logo {
    width: 160px;
    margin-top:12%;
    margin-left:5%;
    margin-bottom: 12%;
 }
 .menu-item {
     margin-top:3px; 
     margin-bottom:3px;
 }
 .empty-sidebar-div{
     padding-top: 20vh;
     padding-bottom: 20vh;
 }
 .sidebar-icons-margin{
    margin-left: 10px;
 }
 .sidebar-item-txt{
    color: #fff !important;
    font-family: 'Rubik', sans-serif;
    font-size: 17px;
    padding-top: 17px;
    font-weight: 600;
 }
 .btn {
     background-color: transparent;
     border: 0px solid;
     color: #fff !important;
     font-size: 14px !important;
     padding-left: -50px;
 }
 .submenu-dot {
     margin-right: 14px;
 }
 hr.sidebar_divider{
    color: #fff;
    width: 85%;
    margin-left: 6.5%;
 }