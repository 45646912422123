.comingSoon{
    height: 80vh;
    background-color: #f5f5f5;
    width: 100vw;
    padding-top: 40vh;
}
p.comingSoon_txt{
    text-align: center;
    font-size: 25px;
    font-family: 'Rubik', sans-serif;
}