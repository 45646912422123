.more_View{
    width: 150px;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    z-index: 99999;
    right: 0;
    margin-top: -40px;
    margin-right: 32.6em;
    float: right;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
button.othersGrey{
    width: 100%;
    border: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #fff;
    color: #242424;
    height: 50px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    padding-left: 10px;
    font-family: 'Rubik', sans-serif;
}
button.othersActive{
    width: 100%;
    background-color: #0D67FE;
    border: none;
    color: #fff;
    height: 50px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    padding-left: 10px;
    font-family: 'Rubik', sans-serif;
}
button.othersWhite{
    width: 100%;
    background-color: #fff;
    border: none;
    color: #242424;
    height: 50px;
    text-align: center;
    text-decoration: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    padding-left: 10px;
    font-family: 'Rubik', sans-serif;
}
span.othersTxt{
    text-align: left;
}
hr.dropDownOptions{
    height: 0.5px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 20px;
    margin-right: 20px;
}
.BsChevronDown-icon{
    margin-left: 5px;
}