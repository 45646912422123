@media only screen and (min-width: 600px) {
    .profile_navbar{
        width: 100vw;
        background-color: #092546;
        height: 70px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        padding-left: 2%;
        padding-right: 2%;
        align-items: center !important;
        align-self: center !important;
        padding-top: 0.6%;
        display: flex;
    }
    .profile_body{
        background-color: #113258;
        width: 100vw;
        padding-left: 30%;
        padding-right: 30%;
        padding-top: 5%;
        padding-bottom: 7%;
    }
    input.profile_emailInput{
        border-radius: 10px;
        padding-left: 10px;
        width: 398px;
        border: 0px solid #C6C6C6;
        height: 54px;
        padding-left: 4%;
        margin-top: 20px;
        background: #15213C;
        color: #fff;
    }
    .profile_social_button{
        box-sizing: border-box;
        width: 398px;
        height: 58px;
        border: 1px solid #C6C6C6;
        border-radius: 8px;
        margin-top: 2.7%;
        background-color: transparent;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 13px;
        padding-left: 6%;
        padding-right: 6%;
    }
    .profile_social_button_connected{
        box-sizing: border-box;
        width: 398px;
        height: 58px;
        border: 1px solid #73c255;
        background-color: #73c255;
        border-radius: 8px;
        margin-top: 2.7%;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 13px;
        padding-left: 6%;
        padding-right: 6%;
    }
    .profile_save_btn{
        box-sizing: border-box;
        width: 398px;
        height: 58px;
        border-radius: 8px;
        margin-top: 10%;
        background-color: #136EB7;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 13px;
        text-align: center;
    }
}
@media only screen and (max-width: 599px) {
    .profile_navbar{
        width: 100vw;
        background-color: #092546;
        height: 70px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        padding-left: 4%;
        padding-right: 4%;
        align-items: center !important;
        align-self: center !important;
        padding-top: 3%;
        display: flex;
    }
    .profile_body{
        background-color: #113258;
        width: 100vw;
        padding-left: 3%;
        padding-right: 3%;
        padding-top: 10%;
        padding-bottom: 7%;
    }
    input.profile_emailInput{
        border-radius: 10px;
        padding-left: 10px;
        width: 100%;
        border: 0px solid #C6C6C6;
        height: 54px;
        padding-left: 6%;
        margin-top: 20px;
        background: #15213C;
        color: #fff;
    }
    .profile_social_button{
        box-sizing: border-box;
        width: 100%;
        height: 58px;
        border: 1px solid #C6C6C6;
        border-radius: 8px;
        margin-top: 4%;
        background-color: transparent;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 13px;
        padding-left: 6%;
        padding-right: 6%;
    }
    .profile_save_btn{
        box-sizing: border-box;
        width: 100%;
        height: 58px;
        border-radius: 8px;
        margin-top: 10%;
        background-color: #136EB7;
        cursor: pointer;
        align-self: center !important;
        align-items: center !important;
        padding-top: 13px;
        text-align: center;
    }
}
.back-icon{
    color: #fff;
    cursor: pointer;
}
p.nav_headerText{
    font-weight: 600;
    font-size: 20px;
    color: #fff;
}
.profile_image{
    height: 142px;
    width: 142px;
    border-radius: 150px;
    border: 2px solid #fff;
}
.profile_image_empty{
    height: 142px;
    width: 142px;
    border-radius: 150px;
}
.profile_below_image_txt{
    font-weight: 400;
    font-size: 12.5px;
    margin-top: 5%;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.9;
}
input.profile_emailInput:focus {
    border: 0px solid #113258;
    outline:none;
}
input.profile_emailInput::placeholder{
    color: #fff;
}
p.profile_save_btn_txt{
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    padding-top: 3px;
    color: #fff;
}
.empty_image_profile{
    border: 1.5px solid #FFFFFF;
    border-radius: 102.5px;
    width: 142px;
    height:142px;
}
input[type=file] {
    display: none;
  }